<template>
  <b-modal
      id="modal-brand-create"
      title="Создание нового бренда"
      ok-title="Сохранить"
      @ok="save"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="form-group">
      <label class="title">Название *</label>
      <input type="text" class="form-control" v-model="form.name">
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
  name: 'BrandCreateModal',
  components: {
    BModal,
  },
  data() {
    return {
      form: {
        name: null,
      },
    };
  },
  methods: {
    async save() {
      if (!this.form.name) {
        this.$bvToast.toast('Заполните все поля', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        return false;
      }

      try {
        await this.$api.brands.create(this.form);
        this.$bvToast.toast('Бренд успешно создан', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.$emit('update');
      } catch (e) {
        this.$bvToast.toast('Ошибка при создании бренда', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      return true;
    },
  },
};
</script>
