<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <app-breadcrumb />
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-2">
              <div class="row">
                <div class="col col-12 col-md-6 d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <button
                      v-b-modal.modal-brand-create
                      v-if="$can('manage', 'brands')"
                      class="btn btn-primary"
                  >
                    <span class="text-nowrap">Добавить Бренд</span>
                  </button>
                </div>
                <div class="col-12 col-md-6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        v-model="searchQuery"
                        @change="getBrands" class="d-inline-block mr-1"
                        placeholder="Поиск..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ brands.from }} по {{ brands.to }} из {{ brands.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="brands.total"
                      :per-page="brands.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Категории</th>
                  <th>Пользователи</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="brand in brands.data" :key="brand.id">
                  <td>
                    <router-link :to="{name: 'brand-details', params: { id: brand.id }}">
                      {{ brand.id }}
                    </router-link>
                  </td>
                  <td>{{ brand.name }}</td>
                  <td>{{ brand.category_dictionaries_count || '-' }}</td>
                  <td>{{ brand.user_dictionaries_count || '-' }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ brands.from }} по {{ brands.to }} из {{ brands.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="brands.total"
                      :per-page="brands.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <BrandCreateModal @update="getBrands"/>
  </div>
</template>

<script>
import { BFormInput, BPagination } from 'bootstrap-vue';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import BrandCreateModal from '@/views/brands/BrandCreateModal.vue';

export default {
  name: 'BrandList',
  components: {
    BFormInput,
    BPagination,
    AppBreadcrumb,
    BrandCreateModal,
  },
  data() {
    return {
      currentPage: 1,
      breadcrumbs: [],
      selectedBrand: null,
      brands: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
      searchQuery: null,
    };
  },
  watch: {
    async currentPage(newValue) {
      this.brands.current_page = newValue;
      await this.getBrands();
    },
  },
  async mounted() {
    await this.getBrands();
  },
  methods: {
    async getBrands() {
      this.brands = (await this.$api.brands.list({
        ...(this.brands.current_page > 1 && { page: this.brands.current_page }),
        limit: 25,
        ...(this.searchQuery && { search: this.searchQuery }),
      })).data;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
